import { Flex, Stack, Text, type TextProps } from '@backyard-ui/core';

import { type ModularContent } from '@/infra/services/modular-content/model';

import { formatPrice } from '../../ProductThumb.utils';
import { Installments } from '../Installments';

type Product = ModularContent.Product;

export interface PriceProps {
  /**
   *
   */
  meta?: Product['meta'];

  /**
   *
   */
  price: Product['price'];

  /**
   *
   */
  unit: Product['unit'];

  /**
   *
   */
  isOffer: boolean;

  /**
   *
   */
  className?: string;

  /**
   * value that determines if it should not show the product as a offer even it having discount value
   */
  featureInstallmentDiscount: boolean;
}

type TextColors = {
  priceDetails: TextProps['color'];
  toPrice: TextProps['color'];
};

function Price(props: PriceProps) {
  const {
    meta,
    price,
    unit,
    className,
    isOffer,
    featureInstallmentDiscount,
    ...rest
  } = props;

  const fullPrice = price.suggested || price.from || price.region;

  const buildTextColors = (): TextColors => {
    if (meta?.isOutlet) {
      return {
        priceDetails: 'white',
        toPrice: 'green-500',
      };
    }

    if (isOffer && !featureInstallmentDiscount) {
      return {
        priceDetails: 'black',
        toPrice: 'red-600',
      };
    }

    return {
      priceDetails: 'gray-600',
      toPrice: 'gray-900',
    };
  };

  if (meta?.isSoldOut || !price.to) {
    return (
      <div className={className}>
        <span>Produto indisponível</span>
      </div>
    );
  }

  return (
    <Flex
      direction="column"
      className={className}
      {...rest}
      itemScope
      itemProp="offers"
      itemType="https://schema.org/Offer"
    >
      <meta itemProp="availability" content="https://schema.org/InStock" />
      <meta
        itemProp="itemCondition"
        content="https://schema.org/NewCondition"
      />
      <meta itemProp="priceCurrency" content="BRL" />
      <meta itemProp="itemPrice" content={String(price.to)} />
      <Stack direction="column" spacing="0.5">
        {fullPrice && !featureInstallmentDiscount && (
          <Text
            color={buildTextColors().priceDetails}
            size="sm"
            decoration="line-through"
            data-testid="ProductThumb-full-price"
            asChild
          >
            <span>{formatPrice(fullPrice)}</span>
          </Text>
        )}

        <Stack spacing="1" align="flex-end">
          <Text
            color={buildTextColors().toPrice}
            size="xl"
            weight="bold"
            data-testid="to-price"
            asChild
          >
            <span>{formatPrice(price.to)}</span>
          </Text>
          <Text
            color={buildTextColors().toPrice}
            size="lg"
            weight="bold"
            asChild
          >
            <span>{unit}</span>
          </Text>
        </Stack>

        {price.discount?.labels.paymentMethod && (
          <Text
            color={buildTextColors().priceDetails}
            size="xs"
            data-testid="ProductThumb-payment-method"
            asChild
          >
            <span>{price.discount?.labels.paymentMethod}</span>
          </Text>
        )}

        <Installments
          price={price}
          textColor={buildTextColors().priceDetails}
          featureInstallmentDiscount={featureInstallmentDiscount}
        />
      </Stack>
    </Flex>
  );
}

export default Price;
