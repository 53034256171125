import { type ModularContent } from '@/infra/services/modular-content/model';

import { pushDataObject } from '../data-layer';
import { sendToApp } from '../send-to-app';

type Product = ModularContent.Product;

export const createWishlist = (listName: string) => {
  pushDataObject({
    event: 'create_wishlist',
    wishlist_name: listName,
  });
};

type AddProductToWishlistParams = {
  product: Product;
  listName: string;
};

export const addProductToWishlist = ({
  product,
  listName,
}: AddProductToWishlistParams) => {
  const category = product.categoryTree.split('/');
  const price = product.price.to?.toFixed(2);

  pushDataObject({
    event: 'add_to_wishlist',
    wishlist_name: listName,
    currency: 'BRL',
    value: price,
    items: [
      {
        name: product.shortName,
        id: product.id,
        price: price,
        brand: product.brand,
        category: category[0] || undefined,
        category2: category[1] || undefined,
        category3: category[2] || undefined,
        category4: category[3] || undefined,
        variant: undefined,
      },
    ],
  });

  sendToApp({
    event: 'add_to_wishlist',
    properties: {
      currency: 'BRL',
      items: [
        {
          item_id: product.id,
          item_name: product.shortName,
          discount: product.price.discount?.percentage,
          item_brand: product.brand,
          item_category: category[0] || '',
          item_category2: category[1] || '',
          item_category3: category[2] || '',
          item_category4: category[3] || '',
          item_category5: category[4] || '',
          price: price,
          currency: 'BRL',
        },
      ],
      value: price,
    },
  });
};

export const removeProductFromWishlist = (product: Product) => {
  const price = product.price.to?.toFixed(2);

  pushDataObject({
    event: 'remove_from_wishlist',
    wishlist_name: undefined,
    item_id: product.id,
    value: price,
  });
};
